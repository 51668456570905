<template>
    <div>

        <div class="container_site">
        
            <div class="container_about_observatorio">
                <img class="container_about_observatorio__banner" src="@/assets/img/banners/banner_vista-3.jpg">
            </div>


            <div class="about_observatorio">
                <img class="about_observatorio__img" src="@/assets/img/que_es_el_observatorio_titulo.png" style="width: 99%;" v-if="lang == 'es'">

                <img class="about_observatorio__img" src="@/assets/img/What-is.png" style="width: 99%;" v-if="lang == 'en'">

                <div class="about_observatorio__container_text">
                    <p class="about_observatorio__container_text--text" v-html="getClient().what">
                     
                    </p>
                </div>
            </div>

            <div class="about_observatorio">
                <img class="about_observatorio__img" src="@/assets/img/para_que_sirve_titulo.png" v-if="lang == 'es'">

                <img class="about_observatorio__img" src="@/assets/img/Who-is-for.png" v-if="lang == 'en'">

                <div class="about_observatorio__container_text2">
                    <p class="about_observatorio__container_text2--text" v-html="getClient().for">
                       
                    </p>
                </div>
            </div>

            <div class="about_observatorio">
                <img class="about_observatorio__img" src="@/assets/img/vision_titulo.png" v-if="lang == 'es'">

                <img class="about_observatorio__img" src="@/assets/img/Vision.png" v-if="lang == 'en'">

                <div class="about_observatorio__container_text">
                    <p class="about_observatorio__container_text--text"  v-html="getClient().why">
                       
                    </p>
                </div>
            </div>

            <div class="about_observatorio">
                <img class="about_observatorio__img" src="@/assets/img/a_quien_va_dirigido.png" v-if="lang == 'es'">

                <img class="about_observatorio__img" src="@/assets/img/What-is-for.png" v-if="lang == 'en'">

                <div class="about_observatorio__container_list">
                    <ul class="about_observatorio__container_list--list" style="font-weight: 900;" v-html="getClient().how">

                    </ul>
                </div>
            </div>

            <div class="about_observatorio_footer">
                <h1 class="about_observatorio_footer__title">De dónde proviene la información</h1>

                <div class="about_observatorio__container_list">

                </div>
            </div>


            <div class="about_observatorio_icons_footer" v-if="screenWidth > '575.98'">
                <div class="about_observatorio_icons_footer_row" >
                    <img class="about_observatorio_icons_footer__img" src="@/assets/img/icono_de_documentos.png">
                    <h2 class="about_observatorio_icons_footer__title">Documentos</h2>
                </div>

                <div class="about_observatorio_icons_footer_row" >
                    <img class="about_observatorio_icons_footer__img" src="@/assets/img/icono_de_estadisticas.png">
                    <h2 class="about_observatorio_icons_footer__title">Estadísticas</h2>
                </div>

                <div class="about_observatorio_icons_footer_row" >
                    <img class="about_observatorio_icons_footer__img" src="@/assets/img/icono_de_mapas.png">
                    <h2 class="about_observatorio_icons_footer__title">Mapas</h2>
                </div>

                <div class="about_observatorio_icons_footer_row" >
                    <img class="about_observatorio_icons_footer__img" src="@/assets/img/icono_de_indicadores.png">
                    <h2 class="about_observatorio_icons_footer__title">Indicadores</h2>
                </div>

                <div class="about_observatorio_icons_footer_row" >
                    <img class="about_observatorio_icons_footer__img" src="@/assets/img/icono_de_encuestas.png">
                    <h2 class="about_observatorio_icons_footer__title">Encuestas</h2>
                </div>

            </div>    

   
   
        <vue-glide v-else class="carousel_icon"
            perView="3"
            startAt="0" 
            autoplay="true"
            rewindDuration="2000"
            animationDuration="1000"
        >
            <vue-glide-slide>
                <img class="carousel_icon__icon" src="@/assets/img/icono_de_documentos.png">
                <h2 class="carousel_icon__title">Documentos</h2>
            </vue-glide-slide>
            <vue-glide-slide>
                <img class="carousel_icon__icon" src="@/assets/img/icono_de_estadisticas.png">
                <h2 class="carousel_icon__title">Estadísticas</h2>
            </vue-glide-slide>
            <vue-glide-slide>
                <img class="carousel_icon__icon" src="@/assets/img/icono_de_mapas.png">
                <h2 class="carousel_icon__title">Mapas</h2>
            </vue-glide-slide>
            <vue-glide-slide>
                <img class="carousel_icon__icon" src="@/assets/img/icono_de_indicadores.png">
                <h2 class="carousel_icon__title">Indicadores</h2>
            </vue-glide-slide>
            <vue-glide-slide>
                <img class="carousel_icon__icon" src="@/assets/img/icono_de_encuestas.png">
                <h2 class="carousel_icon__title">Encuestas</h2>
            </vue-glide-slide>
        </vue-glide>
      

        
   
</div>

    </div>
</template>
<script>



export default {
    data() {
        return {

            screenWidth: window.innerWidth,
            url: 'map/logo.jpeg',
            lang:'' 
        }
    },

   
    created(){
        this.lang = localStorage.getItem('lang')
    },

    methods: {



     onResize() {
      this.screenWidth = window.innerWidth
    }

    },

  
  mounted() {

    console.log('getClient()', this.getClient())


      this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })

  
 
  },


  beforeDestroy() { 
    window.removeEventListener('resize', this.onResize); 
  },

}
</script>

<style scoped>
</style>